import { Project } from "@/lib/types";
import { CodeBracketSquareIcon, PlusIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { classNames } from "@/lib/helpers";
import { useStore } from "@/lib/store";
import { useRouter } from "next/router";
import { SettingsIcon } from "lucide-react";
import CreateProjectModal, {
  createProjectsModalId,
} from "./CreateProjectModal";
import ReturnToCliModal, { returnToCliModalId } from "./ReturnToCliModal";
import { IconContext } from "react-icons";
import { VscOrganization } from "react-icons/vsc";
import { Theme, defaultThemes } from "@/stores/themes";
import Link from "next/link";
import { IoSparklesOutline } from "react-icons/io5";
import { Sparkle } from "lucide-react";
import UpgradeButton from "../UpgradeButton";

interface SelectorProps {
  createHandler: Function;
}

const ProjectSelectorComponent: React.FC<SelectorProps> = ({
  createHandler,
}) => {
  const router = useRouter();
  const [user, _updateUser] = useStore("userStore", ({ user }) => user);
  const [projects, _updateProjects] = useStore<Project[]>(
    "projectStore",
    ({ projects }) => projects
  );
  const [projectsLoaded, _updateProjectsLoaded] = useStore<boolean>(
    "projectStore",
    ({ projectsLoaded }) => projectsLoaded
  );
  const [_project, updateProject] = useStore(
    "projectStore",
    ({ project }) => project
  );
  const [organization, updateOrganization] = useStore(
    "userStore",
    ({ organization }) => organization
  );
  const [themes, _updateTheme] = useStore<Theme[]>(
    "themesStore",
    ({ themes }) => themes
  );
  const [currentTheme, _setCurrentTheme] = useStore<number>(
    "themesStore",
    ({ currentTheme }) => currentTheme
  );
  const [theme, setTheme] = useState<Theme>(defaultThemes[0]);

  useEffect(() => {
    if (projects.length === 0 && projectsLoaded) {
      document.getElementById(createProjectsModalId)?.showModal();
    }
  }, [projectsLoaded]);

  const { linkGithub } = router.query;
  useEffect(() => {
    if (linkGithub) {
      document.getElementById(createProjectsModalId)?.showModal();
    }
  }, [linkGithub]);

  const { returnToCli } = router.query;
  useEffect(() => {
    if (returnToCli) {
      document.getElementById(returnToCliModalId)?.showModal();
    }
  }, [returnToCli]);

  useEffect(() => {
    setTheme(themes[currentTheme]);
  }, []);

  return (
    <div className="fixed inset-0 flex">
      <div className="flex grow flex-col">
        <div
          className="flex-1 flex flex-col gap-y-5 overflow-y-auto p-0"
          style={{ backgroundColor: theme.editor.background }}
        >
          <div
            className="flex flex-row justify-between pl-4 pr-4 text-center h-[42px] border-b-[1px] tracking-tight pb-4"
            style={{
              backgroundColor: theme.ui.titleBar,
              color: theme.ui.titleBarText,
              borderColor: theme.ui.borders,
            }}
          >
            <div className="flex-1 flex flex-row mt-4 items-center">
              <CodeBracketSquareIcon className="h-6 mr-2 fill-accent" />
              <p className="text-2xl">Projects</p>
            </div>
            <div className="flex-1 flex flex-row items-center justify-center mt-4">
              <label
                htmlFor="org-select-dropdown"
                className="form-control flex flex-row items-center justify-center w-full h-fit max-w-xs"
              >
                <div className="label mr-1">
                  <IconContext.Provider
                    value={{
                      size: "1em",
                      className: "",
                      color: theme.ui.titleBarText,
                    }}
                  >
                    <VscOrganization />
                  </IconContext.Provider>
                </div>
                <select
                  className="select select-sm text-xs"
                  id="org-select-dropdown"
                  onChange={(e) => {
                    const idx = parseInt(e.target.value);
                    updateOrganization({
                      organization: user.organizations[idx],
                    });
                    localStorage.setItem(
                      "organizationId",
                      user.organizations[idx].id.toString()
                    );
                  }}
                >
                  {user &&
                    organization &&
                    user.organizations.map((org, idx) => (
                      <option
                        key={org.id}
                        value={idx}
                        selected={
                          user
                            ? user.organizations.findIndex(
                                (org) => org.id === organization.id
                              )
                            : 0
                        }
                      >
                        {org.name}
                      </option>
                    ))}
                </select>
              </label>
            </div>
            <div className="flex-1 mt-1 text-right">
              <div className="flex flex-row items-center justify-end mt-0 mr-0 pr-0 h-8">
                {organization &&
                  (organization.subscription.type === "INDIVIDUAL" ||
                    organization.subscription.type === "PROFESSIONAL" ||
                    organization.subscription.type === "TEAM") && (
                    <p className="mr-2 border rounded-sm text-xs border-fuchsia-400 text-fuchsia-400 py-0.5 px-1">
                      {organization.subscription.type}
                    </p>
                  )}
                {organization && organization.subscription.type === "ENT" && (
                  <p className="mr-2 border rounded-sm text-xs border-amber-400 text-amber-400 py-0.5 px-1">
                    ENTERPRISE
                  </p>
                )}
                <p>{user ? user.name : ""}</p>
                <button
                  className="btn btn-square btn-sm btn-ghost ml-2 mr-2"
                  onClick={() =>
                    router.push("/settings/organizations?return=1")
                  }
                >
                  <SettingsIcon className="h-6" strokeWidth={"1px"} />
                </button>
              </div>
            </div>
          </div>
          <div className="w-full">
            <ul
              role="list"
              className="text-neutral-content flex flex-row flex-wrap justify-center"
            >
              {projects
                .sort((a, b) => (a.name >= b.name ? 1 : -1))
                .map((project: Project, index: number) => {
                  var alternatingStyle =
                    index % 2 != 0
                      ? "text-neutral-content hover:bg-gray-200 hover:text-accent"
                      : "text-neutral-content hover:bg-gray-200 hover:text-accent";

                  const iconStyle =
                    "fill-accent-content group-hover:fill-accent";
                  return (
                    <li
                      key={project.id}
                      style={{
                        backgroundColor:
                          index % 2 != 0
                            ? theme.ui.sideBar
                            : theme.ui.tabsActive,
                      }}
                      className={classNames(
                        "group w-[300px] flex flex-row items-center justify-between text-center mx-2 my-2 rounded-lg cursor-pointer px-4 py-2 whitespace-nowrap truncate",
                        alternatingStyle
                      )}
                      onClick={() => {
                        updateProject({ project: project.id }, () => {
                          router.replace("/build");
                        });
                      }}
                    >
                      <p className="overflow-x-hidden text-ellipsis">
                        {project.name}
                      </p>
                      <CodeBracketSquareIcon
                        className={classNames("h-6 ml-2 flex-none", iconStyle)}
                      />
                    </li>
                  );
                })}
            </ul>
          </div>
          <ReturnToCliModal button={null} buttonStyles="hidden" />
        </div>
      </div>
    </div>
  );
};

export default ProjectSelectorComponent;
