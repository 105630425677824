import { useStore } from "@/lib/store";
import GenericModal from "../GenericModal";

export const returnToCliModalId = "returnToCliModalId";

interface ReturnToCliModalProps {
  button: Element;
  buttonStyles: string;
}

const ReturnToCliModal: React.FC<ReturnToCliModalProps> = ({
  button,
  buttonStyles,
}) => {
  const [themes, _updateThemes] = useStore<Theme[]>(
    "themesStore",
    ({ themes }) => themes
  );
  const [currentTheme, _setCurrentTheme] = useStore<number>(
    "themesStore",
    ({ currentTheme }) => currentTheme
  );

  const actualTheme = themes[currentTheme];

  return (
    <GenericModal
      title="Create Project"
      id={returnToCliModalId}
      buttonIcon={button}
      modalContainerStyles="overflow-hidden -mt-[20%]"
      buttonStyles={buttonStyles}
    >
      <p
        className="border-[1px] p-8 w-[400px] rounded-lg text-center"
        style={{
          backgroundColor: actualTheme.ui.panels,
          color: actualTheme.ui.titleBarText,
          borderColor: actualTheme.ui.borders,
        }}
      >
        GitHub Linked!
      </p>
    </GenericModal>
  );
};

export default ReturnToCliModal;
